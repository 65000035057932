import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "@fortawesome/fontawesome-free/css/all.min.css";

import { apiLoadAdminById } from "apiaxios";
import RootLayout from "./layouts/RootLayout.js";
import GuestLayout from "./layouts/GuestLayout.js";

let IsLoggedIn = false;
if (sessionStorage.getItem("token")){
  const response = await apiLoadAdminById();
  const {data} = response;
  if (data.success)
    IsLoggedIn = data.data.status;
  else
    IsLoggedIn = data.success;
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(    
<BrowserRouter>
    <Switch>
      <Route key={'root'} path='/root' render={(props) => IsLoggedIn ? <RootLayout {...props} /> : <Redirect to="/guest" />}/>
      <Route key={'guest'} path='/guest/login' render={(props) => !IsLoggedIn ? <GuestLayout {...props} /> : <Redirect to="/root" />}/>
      <Redirect from='/' to={IsLoggedIn ? '/root/transactionlist' : '/guest/login'} />
    </Switch>
</BrowserRouter>
);