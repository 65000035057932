import React from "react";
import { apiLogout } from "../apiaxios";
import "../assets/css/container.css";
import "../assets/css/label.css";
import "../assets/css/button.css";
import { Row, Col, Container, Card } from "reactstrap";

const LogoutPage = () => {

    const clearCache = () => {
        const dynamicCacheBuster = new Date().getTime();
        const stylesheets = document.getElementsByTagName('link');
        for (let i = 0; i < stylesheets.length; i++) {
          if (stylesheets[i].rel === 'stylesheet') {
            stylesheets[i].href += `?v=${dynamicCacheBuster}`;
          }
        }
        const scripts = document.getElementsByTagName('script');
        for (let i = 0; i < scripts.length; i++) {
          scripts[i].src += `?v=${dynamicCacheBuster}`;
        }
    };

    const Logout = async() => {
        await apiLogout();
        sessionStorage.clear();
        clearCache();
        window.location.reload();
    }

    return (
        <>
            <Container className="min-vh-100">
                <Card className="justify-content-center align-items-center border-0 bg-transparent">
                    <Row className="mt-5 p-3 box-shadow w-75">
                        <Col>
                            <p className="label" style={{fontSize: 46, textAlign: "center"}}>Logout from BASOBO</p>
                            <p className="label" style={{fontSize: 23, textAlign: "center"}}>Apakah anda yakin untuk keluar dari sini?</p>
                        </Col>

                        <Col md={{ size: 6, offset: 4 }}>
                            <button className="rounded-blue-button w-50" onClick={Logout}>Logout</button>
                        </Col>
                    </Row>
                </Card>
            </Container>
        </>
    );
}

export default LogoutPage;