import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_DOMAIN_API
});

instance.interceptors.request.use(async (config) => {
    const token = sessionStorage.getItem('token');
    config.headers.Authorization = (token ? token : '');
    config.headers.ContentType = 'application/json';

    return config;
});

export const apiRegister = async (FullName, Email, PhoneNumber, Password) => {
    try {
        const response = await instance.post('user/register', {FullName, Email, PhoneNumber, Password});
        if (response.data == "")
            return {data: {success: false, error: response.statusText}};

        return response;
    } catch (error) {
        if (error.response)
            return {data: {success: false, error: '(' + error.response.status + ') ' + error.response.data.error}};
        else
            return {data: {success: false, error: error.message}};
    }
};

export const apiAdminLogin = async (Email, Password) => {
    try {
        const response = await instance.post('admin/login', {Email, Password});
        if (response.data == "")
            return {data: {success: false, error: response.statusText}};

        return response;
    } catch (error) {
        if (error.response)
            return {data: {success: false, error: '(' + error.response.status + ') ' + error.response.data.error}};
        else
            return {data: {success: false, error: error.message}};
    }
};

export const apiLoadAdminById = async () => {
    try {
        const response = await instance.get('admin/readid', {});
        if (response.data == "")
            return {data: {success: false, error: response.statusText}};

        return response;
    } catch (error) {
        if (error.response)
            return {data: {success: false, error: '(' + error.response.status + ') ' + error.response.data.error}};
        else
            return {data: {success: false, error: error.message}};
    }
};

export const apiMailActivation = async (EncryptedUserId) => {
    try {
        const response = await instance.post('sendmail/linkactivation', {EncryptedUserId});
        if (response.data == "")
            return {data: {success: false, error: response.statusText}};

        return response;
    } catch (error) {
        if (error.response)
            return {data: {success: false, error: '(' + error.response.status + ') ' + error.response.data.error}};
        else
            return {data: {success: false, error: error.message}};
    }
};

export const apiLoadUser = async () => {
    try {
        const response = await instance.get('user/readid', {});
        if (response.data == "")
            return {data: {success: false, error: response.statusText}};

        return response;
    } catch (error) {
        if (error.response)
            return {data: {success: false, error: '(' + error.response.status + ') ' + error.response.data.error}};
        else
            return {data: {success: false, error: error.message}};
    }
};

export const apiLoadUserByAdmin = async () => {
    try {
        const response = await instance.get('user/readbyadmin', {});
        if (response.data == "")
            return {data: {success: false, error: response.statusText}};

        return response;
    } catch (error) {
        if (error.response)
            return {data: {success: false, error: '(' + error.response.status + ') ' + error.response.data.error}};
        else
            return {data: {success: false, error: error.message}};
    }
};

export const apiUpdateUser = async (Id, Email, PhoneNumber, FullName, Dob, Gender, Status) => {
    try {
        const response = await instance.post('user/updateprofilebyadmin', {Id, Email, PhoneNumber, FullName, Dob, Gender, Status});
        if (response.data == "")
            return {data: {success: false, error: response.statusText}};

        return response;
    } catch (error) {
        if (error.response)
            return {data: {success: false, error: '(' + error.response.status + ') ' + error.response.data.error}};
        else
            return {data: {success: false, error: error.message}};
    }
};

export const apiSendOTP = async () => {
    try {
        const response = await instance.get('userotp/senduserotp', {});
        if (response.data == "")
            return {data: {success: false, error: response.statusText}};

        return response;        
    } catch (error) {
        if (error.response)
            return {data: {success: false, error: '(' + error.response.status + ') ' + error.response.data.error}};
        else
            return {data: {success: false, error: error.message}};
    }
};

export const apiValidateOTP = async (Otp) => {
    try {
        const response = await instance.post('userotp/validateuserotp', {Otp});
        if (response.data == "")
            return {data: {success: false, error: response.statusText}};

        return response;             
    } catch (error) {
        if (error.response)
            return {data: {success: false, error: '(' + error.response.status + ') ' + error.response.data.error}};
        else
            return {data: {success: false, error: error.message}};
    }
};

export const apiLoadTransactionSuspend = async () => {
    try {
        const response = await instance.get('transaction/suspend', {});
        if (response.data == "")
            return {data: {success: false, error: response.statusText}};

        return response;
    } catch (error) {
        if (error.response)
            return {data: {success: false, error: '(' + error.response.status + ') ' + error.response.data.error}};
        else
            return {data: {success: false, error: error.message}};
    }
};

export const apiLoadTransactionById = async (Id) => {
    try {
        const response = await instance.post('transaction/readid', {Id});
        if (response.data == "")
            return {data: {success: false, error: response.statusText}};

        return response;
    } catch (error) {
        if (error.response)
            return {data: {success: false, error: '(' + error.response.status + ') ' + error.response.data.error}};
        else
            return {data: {success: false, error: error.message}};
    }
};

export const apiAdminCancelComplete = async (Id, Remark) => {
    try {
        const response = await instance.post('transaction/admincancelcomplete', {Id, Remark});
        if (response.data == "")
            return {data: {success: false, error: response.statusText}};

        return response;
    } catch (error) {
        if (error.response)
            return {data: {success: false, error: '(' + error.response.status + ') ' + error.response.data.error}};
        else
            return {data: {success: false, error: error.message}};
    }
};

export const apiTrackingChange = async (Track, Id) => {
    try {
        const response = await instance.post(`transaction/${Track}`, {Id});
        if (response.data == "")
            return {data: {success: false, error: response.statusText}};

        return response;
    } catch (error) {
        if (error.response)
            return {data: {success: false, error: '(' + error.response.status + ') ' + error.response.data.error}};
        else
            return {data: {success: false, error: error.message}};
    }
};

export const apiCreateTransactionForum = async (TransactionId, Comment, Attachment) => {
    try {
        const response = await instance.post('transactionforum/create', {TransactionId, Comment, Attachment});
        if (response.data == "")
            return {data: {success: false, error: response.statusText}};

        return response;
    } catch (error) {
        if (error.response)
            return {data: {success: false, error: '(' + error.response.status + ') ' + error.response.data.error}};
        else
            return {data: {success: false, error: error.message}};
    }
};

export const apiLoadTransactionForumByTransactionId = async (TransactionId) => {
    try {
        const response = await instance.post('transactionforum/readtransactionid', {TransactionId});
        if (response.data == "")
            return {data: {success: false, error: response.statusText}};

        return response;
    } catch (error) {
        if (error.response)
            return {data: {success: false, error: '(' + error.response.status + ') ' + error.response.data.error}};
        else
            return {data: {success: false, error: error.message}};
    }
};

export const apiLoadTransactionForumByTransactionIdLastCreatedOn = async (TransactionId, CreatedOn) => {
    try {
        const response = await instance.post('transactionforum/readtransactionidlastcreatedon', {TransactionId, CreatedOn});
        if (response.data == "")
            return {data: {success: false, error: response.statusText}};

        return response;
    } catch (error) {
        if (error.response)
            return {data: {success: false, error: '(' + error.response.status + ') ' + error.response.data.error}};
        else
            return {data: {success: false, error: error.message}};
    }
};

export const apiLogout = async () => {
    try {
        const response = await instance.get('user/logout', {});
        if (response.data == "")
            return {data: {success: false, error: response.statusText}};

        return response;
    } catch (error) {
        if (error.response)
            return {data: {success: false, error: '(' + error.response.status + ') ' + error.response.data.error}};
        else
            return {data: {success: false, error: error.message}};
    }
};