import React, {useState} from 'react';
import { useLocation, NavLink } from "react-router-dom";
import { Nav } from "react-bootstrap";
import '../assets/css/sidebar.css';

function Sidebar({ routes }) {
  const location = useLocation();
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  
  const [openMenus, setOpenMenus] = useState({});
  const toggleMenu = (menuKey) => {
    setOpenMenus({
      ...openMenus,
      [menuKey]: !openMenus[menuKey]
    });
  };

  const logoStyle = {
    width: '400px !important',
    height: '400px !important'
  };

  return (
      <div className="sidebar">
        <div className="sidebar-wrapper">
          <div className="logo d-flex align-items-center justify-content-center">
            <h2>ADMIN</h2>
          </div>
          <Nav>
            {routes.map((prop, key) => {
              return prop.sidebar ? (
                <li className={activeRoute(prop.layout + prop.path)} key={key}>
                  {prop.children ? (
                    <div>
                      <NavLink to="#" className="nav-link" activeClassName="" onClick={() => toggleMenu(key)}>
                        <p className="sidebar-label">
                          <img src={prop.icon} width="20" height="20" style={{ marginRight: '8px' }} />
                          {prop.name}
                        </p>
                      </NavLink>
                      <ul className={`dropdown ${openMenus[key] ? 'show' : ''}`}>
                        {prop.children.map((child, index) => (
                          <li key={index}>
                            <NavLink to={child.layout + child.path} className="nav-link" activeClassName="active">
                              <p className="sidebar-label">
                                <img src={child.icon} width="20" height="20" style={{ marginRight: '8px' }} />
                                {child.name}
                              </p>
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <NavLink to={prop.layout + prop.path} className="nav-link" activeClassName="active" onClick={()=>setOpenMenus(false)}>
                      <p className="sidebar-label">
                        <img src={prop.icon} width="20" height="20" style={{ marginRight: '8px' }} />
                        {prop.name}
                      </p>
                    </NavLink>
                  )}
                </li>
              ) : null;
            })}
          </Nav>
        </div>
      </div>
  );
}

export default Sidebar;