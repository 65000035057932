import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import routes from "../routes.js";
import Footer from "components/Footer";

function GuestLayout() {
  const location = useLocation();
  const mainPanel = React.useRef(null);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/guest") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => <prop.component {...props} />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const contentStyle = {
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center',
    'height': '82%'
  }

  return (
    <>
      <div className="wrapper">
        <div style={{ minHeight: '100vh', position: 'relative' }} ref={mainPanel}>
          <div style={contentStyle}>
            <Switch>
              {getRoutes(routes)}
              <Redirect key={'default'} from='*' to={'/guest/index'} />
            </Switch>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default GuestLayout;