import React, { useState,useEffect } from "react";
import { Container, Button, Row, Col, FormGroup, Input, Label, Alert, Card, CardBody, CardHeader } from "reactstrap";
import { apiLoadUserByAdmin, apiUpdateUser } from "../apiaxios";
import { maskFormatDate } from "global";
import { GenderList,StatusActive } from "itemlists";
import "../assets/css/container.css";
import "../assets/css/button.css";
import { maskFormatPhone } from "global";

const UserListPage = () => {

    const [User, setUser] = useState([{email: 'example@domain.com', phonenumber: '+6200000000', fullname: 'Full name', dob: '01-01-1900', gender: 'Pria', status: true}]);
    const [Id, setId] = useState('');
    const [Email, setEmail] = useState('');
    const [PhoneNumber, setPhoneNumber] = useState('');
    const [FullName, setFullName] = useState('');
    const [Dob, setDob] = useState('');
    const [Gender, setGender] = useState();
    const [Status, setStatus] = useState();

    const [visibleDiv, setVisibleDiv] = useState("1");
    const [Error, setError] = useState('');
    const [ShowAlertError, setShowAlertError] = useState(false);

    const onDismissError = () => setShowAlertError(false);

    const LoadUser = async () => {
        const response = await apiLoadUserByAdmin();
        const { data } = response;
        if (data.success){
            setUser(data.data);
        }
    };

    useEffect(() => {
        let timeout;
        if (ShowAlertError) {
            timeout = setTimeout(() => {
                onDismissError();
            }, 5000);

            return () => clearTimeout(timeout);
        } 
    }, [ShowAlertError, onDismissError]);

    useEffect(() => {
        LoadUser();
    }, []);

    const handleClick = (e) => {
        setId(e._id);
        setFullName(e.fullname);
        setPhoneNumber(e.phonenumber);
        setEmail(e.email);
        setGender(e.gender ? e.gender : 0);
        setDob(e.dob);
        setStatus(e.status);
        setVisibleDiv("2");
    };

    const UpdateUser = async () => {
        const response = await apiUpdateUser(Id, Email, PhoneNumber, FullName, maskFormatDate(Dob), Number(Gender), Status);
        const {data} = response;
        if (data.success){
            setVisibleDiv("1");
            setShowAlertError(false);
            LoadUser();
        }
        else {
            setError(data.error);
            setShowAlertError(true);
        }        
    };

    const handleGender = (e) => {
        if (e.target.checked) {
            setGender(!Gender);
        }
    };

    const truncateText = (text, maxLength) => {
        return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
    };

    return (
        <>

            <Container className="min-vh-100">
                <Card className="justify-content-center align-items-center border-0 bg-transparent"
                    style={{ display: visibleDiv === "1" ? "flex" : "none" }}>
                    <Row className="mt-5">
                        <p className="mt-3" style={{"fontWeight":"bold"}}>Daftar Pengguna BASOBO</p>
                    </Row>

                    <Row className="mt-2 box-shadow w-75">        
                        <Row className="mt-4">
                            {User.map((e,i) => 
                                <Col md={6} key={i} style={{cursor:"pointer"}} onClick={()=>handleClick(e)}>
                                    <Card style={{background:"lightblue", padding:20}}>
                                        <Row className="mb-3">
                                            <Col>
                                                <p style={{fontSize:10, margin:0, padding:0}}>Nama Lengkap</p>
                                                {e.fullname}
                                            </Col>
                                            <Col>
                                                <p style={{fontSize:10, margin:0, padding:0}}>Status</p>
                                                {StatusActive[e.status ? 1 : 0]}  
                                            </Col>                                      
                                        </Row>

                                        <Row className="mb-3">
                                            <Col>
                                                <p style={{fontSize:10, margin:0, padding:0}}>Email</p>
                                                {truncateText(e.email, 30)}
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Col>
                                                <p style={{fontSize:10, margin:0, padding:0}}>Handphone</p>
                                                {e.phonenumber ? e.phonenumber : "+62"}
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Col>
                                                <p style={{fontSize:10, margin:0, padding:0}}>Tanggal Lahir</p>
                                                {maskFormatDate(e.dob)}
                                            </Col>
                                            <Col>
                                                <p style={{fontSize:10, margin:0, padding:0}}>Jenis Kelamin</p>
                                                {GenderList[e.gender]}
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            )} 
                        </Row>
                    </Row>           
                </Card>

                <Card className="justify-content-center align-items-center border-0 bg-transparent"
                        style={{ display: visibleDiv === "2" ? "flex" : "none" }}>
                    <Row className="mt-5">
                        <p className="mt-3" style={{"fontWeight":"bold"}}>Ubah Data User</p>
                    </Row>

                    <Row className="p-3 mt-2 box-shadow w-75">    
                        <Row>
                            <FormGroup>
                                <Label className="label">Nama Lengkap</Label>
                                <Input value={FullName} maxLength={50} onChange={e => setFullName(e.target.value)}/>
                            </FormGroup>                            
                        </Row>

                        <Row>
                            <FormGroup>
                                <Label className="label">Email</Label>
                                <Input value={Email} maxLength={50} onChange={e => setEmail(e.target.value)}/>
                            </FormGroup>                            
                        </Row>

                        <Row>
                            <FormGroup>
                                <Label className="label">Nomor Handphone</Label>
                                <Input maxLength={50} value={PhoneNumber} onChange={(e) => setPhoneNumber(maskFormatPhone(e.target.value))}/>
                            </FormGroup>                            
                        </Row>     

                        <Row>
                            <FormGroup>
                                <Label className="label">Tanggal Lahir</Label>
                                <Input type="date" value={maskFormatDate(Dob)} onChange={e => setDob(e.target.value)}/>
                            </FormGroup>                            
                        </Row>     

                        <Row>
                            <FormGroup>
                                <Label className="label">Jenis Kelamin</Label><br/>
                                <FormGroup check inline>
                                    <Label className="label">
                                        <Input type="radio" value="Gender"
                                                checked={!Gender}
                                                onChange={(e) => {handleGender(e)}}/>
                                        Wanita
                                    </Label>
                                </FormGroup>

                                <FormGroup check inline>
                                    <Label className="label">
                                        <Input type="radio" value="Gender"
                                                checked={Gender}
                                                onChange={(e) => {handleGender(e)}}/>
                                        Pria
                                    </Label>
                                </FormGroup>
                            </FormGroup>                            
                        </Row>                             
                        
                        <Row className="mt-3">
                            <Label className="label">
                                <Input type="checkbox" style={{marginRight:10}} checked={Status} onChange={() => setStatus(!Status)}/>{Status? 'Active' : 'Inactive'}
                            </Label> 
                        </Row>                  
                    </Row>                    

                    <Row className="mt-3 w-50">
                        <Col md={6}>
                            <Button className={'rounded-blue-button w-100'} onClick={()=> UpdateUser()}>Ubah Data</Button>
                        </Col>
                        <Col md={6}>
                            <Button className={'rounded-blue-button w-100'} onClick={()=> setVisibleDiv("1")}>Batal</Button>
                        </Col>                        
                    </Row>
                </Card>

                <Alert color="danger" isOpen={ShowAlertError} toggle={onDismissError}>
                    {Error}
                </Alert>                   
            </Container>
        </>
    );
}

export default UserListPage;