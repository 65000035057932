import "../assets/css/container.css";
import React, { useState,useEffect } from "react";
import { Container, Card, CardHeader, CardBody, Button, Row, Col, Label, Alert, Input } from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import { apiLoadTransactionById, apiCreateTransactionForum, apiLoadTransactionForumByTransactionIdLastCreatedOn, apiAdminCancelComplete } from "../apiaxios";
import { maskFormatDateShort, maskFormatCurrency } from "global";
import { TrackingList, CategoryValue, RemarkList, RemarkValue } from "itemlists";
import "../assets/css/button.css";

const TransactionPage = () => {
    const Location = useLocation();
    const history = useHistory();

    const [Transaction, setTransaction] = useState([]);
    const [Messages, setMessages] = useState([]);
    const [NewMessage, setNewMessage] = useState('');
    const [LastMessageTime, setLastMessageTime] = useState(null);

    const [Error, setError] = useState('');
    const [ShowAlert, setShowAlert] = useState(false);
    const [ShowAlertError, setShowAlertError] = useState(false);

    const onDismiss = () => setShowAlert(false);
    const onDismissError = () => setShowAlertError(false);

    useEffect(() => {
        let timeout;
        if (ShowAlert) {
            timeout = setTimeout(() => {
                onDismiss();
                history.push('/user/transactionlist')
            }, 5000);

            return () => clearTimeout(timeout);
        } 

        if (ShowAlertError) {
            timeout = setTimeout(() => {
                onDismissError();
            }, 5000);

            return () => clearTimeout(timeout);
        } 
    }, [ShowAlert, onDismiss, ShowAlertError, onDismissError]);

    useEffect(() => {
        const params = new URLSearchParams(Location.search);
        const id = params.get('id');

        const LoadTransaction = async() => {
            const response = await apiLoadTransactionById(id);
            const {data} = response;
            if (data.success){
                setTransaction(data.data);
                LoadMessage(data.data._id, data.data.createdon);
            }
            else {
                setError(data.error);
                setShowAlertError(true);
            }
        }

        LoadTransaction();
    }, [Location.search]);

    useEffect(() => { 
        const interval = setInterval(async () => {
            await LoadMessage(Transaction._id, LastMessageTime);
         }, 3000);
        return () => clearInterval(interval);
    }, [LastMessageTime]);

    const LoadMessage = async(TransactionId, CreatedOn) => {
        if (!TransactionId) return;

        const response = await apiLoadTransactionForumByTransactionIdLastCreatedOn(TransactionId, CreatedOn);
        const {data} = response;
        if (data.success)
        {
            const newData = data.data;
            if (newData.length > 0) {
                setMessages(currData => [...currData, ...newData]);
                setLastMessageTime(newData[newData.length - 1].createdon);
            }            
        } else {
            setLastMessageTime(new Date().toISOString());
        }
    };

    const SaveMessage = async(value) => {
        const response = await apiCreateTransactionForum(Transaction._id, value);
        const {data} = response;
        if (data.success !== true) {
            setError(data.error);
            setShowAlertError(true);
        }
    };

    const handleMessageSend = (e) => {
      e.preventDefault();
      if (NewMessage.trim() !== '') {
        SaveMessage(NewMessage);
        setNewMessage('');
      }
    };

    const handleAction = async (Remark) => {
        const response = await apiAdminCancelComplete(Transaction._id, Remark);
        const {data} = response;
        if (data.success) {
          setShowAlert(true);
        } else {
          setError(data.error);
          setShowAlertError(true);
        }
    };

    const CheckName = (category) => {
        if (Transaction.transactioncategory == category) 
            return Transaction.creator 
        else 
            return Transaction.guest
    };

    const CheckSeller = (userid) => {
        if (Transaction.transactioncategory == CategoryValue.sell) 
        {
            if (Transaction.userid == userid)
                return CategoryValue.sell;
            else if (Transaction.useridguest == userid)
                return CategoryValue.buy;
            else return '';
        } else if (Transaction.transactioncategory == CategoryValue.buy) {
            if (Transaction.userid == userid)
                return CategoryValue.buy;
            else if (Transaction.useridguest == userid)
                return CategoryValue.sell;
            else return '';
        } else return ''
    };

    return (
        <>
            <Container className="min-vh-100">    
                <Card className="justify-content-center align-items-center border-0 bg-transparent">
                    <Row className="p-3 box-shadow w-75 mt-5">              
                        <Row className="m-1">
                            <Col>
                                <Label className="label m-0">Penjual :&nbsp;
                                    <Label className="text-black">{CheckName(CategoryValue.sell)}</Label>
                                </Label>
                                <br/>
                                <Label className="label m-0">Pembeli :&nbsp;
                                    <Label className="text-black">{CheckName(CategoryValue.buy)}</Label>
                                </Label>
                            </Col>
                            <Col style={{textAlign:"right"}}>
                                <Label className={"label m-0 text-black"}>{maskFormatDateShort(Transaction.createdon)} &nbsp;</Label>
                                <Label className={`label m-0 ${Transaction.status ? 'text-success' : 'text-danger'}`}>
                                    {Transaction.status ? 'Link Aktif':'Link Non Aktif'}
                                </Label>
                                <br/>
                                <Label className="label m-0">
                                    <Label className="text-black">{RemarkList[Transaction.remark]}</Label>
                                </Label>
                            </Col>
                        </Row>

                        <Row className="m-1">
                            <Col>
                                <Label className="label">Nama Transaksi</Label>
                                <p>{Transaction.transactionname}</p>
                            </Col>

                            <Col>
                                <Label className="label">Harga Produk</Label>
                                <p>{maskFormatCurrency(Transaction.productamount)}</p>
                            </Col>                            
                        </Row>

                        <Row className="m-1">
                            <Col>
                                <Label className="label">Kategori</Label>
                                <p>{Transaction.transactioncategory==0 ? 'Beli' : 'Jual'}</p>
                            </Col>

                            <Col>
                                <Label className="label">Biaya Admin</Label>
                                <p>{maskFormatCurrency(Transaction.adminfee)}</p>
                            </Col>
                        </Row>

                        <Row className="m-1">
                            <Col>
                                <Label className="label">Deskripsi</Label>
                                <p>{Transaction.transactiondesc}</p>
                            </Col>

                            <Col>
                                <Label className="label">Total</Label>
                                <p>{maskFormatCurrency(Transaction.transactionamount)}</p>
                            </Col>
                        </Row>

                        <Row className="p-5">
                            <Card>
                                <CardHeader className="text-center text-success" style={{fontWeight:"bold", letterSpacing: 3}}>{TrackingList[Transaction.tracking]}</CardHeader>
                                <hr/>
                                <CardBody>
                                    <Row className="overflow-y-scroll" style={{maxHeight:250}}>
                                        <div className="chat-bubble-container">
                                            {Messages.map((e, i) => (
                                            <Row key={i} className={`chat-bubble ${ CheckSeller(e.userid) === CategoryValue.sell ? 'align-self-start' 
                                                                                    : CheckSeller(e.userid) === CategoryValue.buy 
                                                                                    ? 'align-self-end' : 'align-self-center'}`}>
                                                <p>
                                                    <small style={{fontSize:8, fontStyle:"italic", color:"grey"}}>
                                                        {new Date(e.createdon).toLocaleString()}
                                                    </small>
                                                    <br/>
                                                    {e.comment}
                                                </p>
                                            </Row>
                                            ))}
                                        </div>
                                    </Row>

                                    <Row>
                                        <Col className="d-flex">
                                            <Input placeholder="Type your message..." value={NewMessage} maxLength={50}
                                                onChange={(e) => setNewMessage(e.target.value)} />
                                            <Button size="xs" onClick={(e)=>handleMessageSend(e)} color="primary">Send</Button>
                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>
                        </Row>

                        <Row className="p-5">
                            <Col md={6}>
                                <Button className={"rounded-purple-button w-100"} onClick={()=>handleAction(RemarkValue.cancel)}>Batalkan Transaksi</Button>
                            </Col>
                            <Col md={6}>
                                <Button className={"rounded-blue-button w-100"} onClick={()=>handleAction(RemarkValue.complete)}>Selesaikan Transaksi</Button>
                            </Col>
                        </Row>

                        <Alert color="success" isOpen={ShowAlert} toggle={onDismiss}>
                            Transaksi berhasil diupdate.
                        </Alert>

                        <Alert color="danger" isOpen={ShowAlertError} toggle={onDismissError}>
                            {Error}
                        </Alert>
                    </Row>
                </Card>
            </Container>
        </>
    );
};

export default TransactionPage;