import React, {useState,useEffect} from "react";
import { Button, Col, FormGroup, Input, Label, Alert, Row, Container, Card } from "reactstrap";
import { useHistory } from "react-router-dom";
import { apiMailActivation, apiRegister } from "../apiaxios";
import emailLogo from '../assets/img/email-logo.svg';
import "../assets/css/container.css";
import "../assets/css/form.css";
import "../assets/css/image.css";
import "../assets/css/button.css";
import "../assets/css/label.css";

const RegisterPage = () => {
    const [Id, setId] = useState('');
    const [FullName, setFullName] = useState('');
    const [Email, setEmail] = useState('');
    const [PhoneNumber, setPhoneNumber] = useState('');
    const [EmailChecked, setEmailChecked] = useState(true);
    const [PhoneNumberChecked, setPhoneNumberChecked] = useState(false);
    const [TermChecked, setTermChecked] = useState(false);
    const [Password, setPassword] = useState('');
    const [Error, setError] = useState('');

    const [visibleDiv, setVisibleDiv] = useState("1");
    const [countdown, setCountdown] = useState(60);
    const [ShowAlert, setShowAlert] = useState(false);
    const [ShowAlertError, setShowAlertError] = useState(false);

    const onDismiss = () => setShowAlert(false);
    const onDismissError = () => setShowAlertError(false);

    const Register = async() => {
        const response = await apiRegister(FullName, Email, PhoneNumber, Password);
        const {data} = response;
        if (data.success) {
            setId(data.id);
            showDiv("2");
        }
        else {
            setError(data.error);
            setShowAlertError(true);
        }
            
    }

    const ResendActivation = async() => {
        const response = await apiMailActivation(Id);
        const {data} = response;
        if (data.success)
            setShowAlert(true);
        else {
            setError(data.error);
            setShowAlertError(true);
        }
    }

    const handleEmailChange = (e) => {
        if (e.target.checked) {
            setPhoneNumber('');
            setEmailChecked(true);
            setPhoneNumberChecked(false);
        }
    };

    const handlePhoneChange = (e) => {
        if (e.target.checked) {
            setEmail('');
            setEmailChecked(false);
            setPhoneNumberChecked(true);
        }
    };

    const showDiv = (id) => {
        setVisibleDiv(id);
    };

    useEffect(() => {
        let timeout;
        if (Id){
            timeout = setTimeout(() => {
            window.location.href = '/';
            }, countdown * 1000);
        
            const interval = setInterval(() => {
                setCountdown(prevCountdown => prevCountdown - 1);
            }, 1000);

            return () => {
                clearTimeout(timeout);
                clearInterval(interval);
            };
        }

        if (ShowAlertError) {
            timeout = setTimeout(() => {
                onDismissError();
            }, 5000);

            return () => clearTimeout(timeout);
        }
    }, [Id, ShowAlertError]);

    const history = useHistory();
    return (
        <>
            <Container className="min-vh-100">
                <Card className="justify-content-center align-items-center border-0 bg-transparent">                
                    <Row className="circle-blue">
                        <div className="group">
                            <div className="ellipse"></div>
                            <div className="ellipse"></div>
                            <div className="ellipse-2"></div>
                            <div className="ellipse-3"></div>
                        </div>

                        <Col className="left-image"></Col>
                        <Col className="register-form" style={{ display: visibleDiv === "1" ? "block" : "none" }}>
                            <p className="label" style={{fontSize: 46, textAlign:"center"}}>Welcome to BASOBO</p>
                            <p className="label m-0" style={{fontSize: 23, textAlign:"center"}}>Isi data untuk mendaftarkan akun ke BASOBO</p>
                            <Row className="p-5">
                                <Col md="12" className="p-1">
                                    <FormGroup>
                                        <Label className="label">Nama Lengkap</Label>
                                        <Input style={{border: "none"}} value={FullName} maxLength={100} onChange={e => setFullName(e.target.value)}/>
                                    </FormGroup>
                                </Col>

                                <Col md="12" className="p-1">
                                    <FormGroup>
                                        <FormGroup check inline>
                                            <Label className="label">
                                                <Input type="radio" value="Email"
                                                        checked={EmailChecked}
                                                        onChange={(e) => {handleEmailChange(e)}}/>
                                                E-mail
                                            </Label>
                                        </FormGroup>

                                        <FormGroup check inline>
                                            <Label className="label">
                                                <Input type="radio" value="PhoneNumber"
                                                        checked={PhoneNumberChecked}
                                                        onChange={(e) => {handlePhoneChange(e)}}/>
                                                Nomor Handphone
                                            </Label>
                                        </FormGroup>
                                        
                                        <Input style={{border: "none", display: EmailChecked ? 'block' : 'none'}} maxLength={50} placeholder="example@domain.com" value={Email} onChange={e => setEmail(e.target.value)}/>
                                        <Input style={{border: "none", display: PhoneNumberChecked ? 'block' : 'none'}} maxLength={50} placeholder="+62xxxxxxxxxxx" value={PhoneNumber} onChange={e => setPhoneNumber(e.target.value)}/>
                                    </FormGroup>
                                </Col>

                                <Col md="12" className="p-1">
                                    <FormGroup>
                                        <Label className="label">Password</Label>
                                        <Input style={{border: "none"}} type="password" value={Password} onChange={e => setPassword(e.target.value)}/>
                                    </FormGroup>
                                </Col>

                                <Col md={{ size: 6, offset: 4 }} className="p-1">
                                    <Label className="label" style={{color:"blueviolet", cursor:"pointer"}} onClick={() => showDiv("3")}>
                                        <Input type="checkbox" checked={TermChecked} onChange={() => setTermChecked(!TermChecked)}/> Syarat dan ketentuan
                                    </Label>
                                </Col>

                                <Col className="d-flex justify-content-center mt-2">
                                    <Button className={`${!TermChecked?'disabled-button':''} rounded-blue-button`} onClick={Register} disabled={!TermChecked}>
                                        Daftar Sekarang
                                    </Button>
                                </Col>
                                <p className="label" style={{margin:10, textAlign:"center"}}>Sudah Punya Akun? <a href="" className="text-primary" onClick={()=> history.push('/guest/login')}>Login</a></p>
                            </Row>
                        </Col>

                        <Col className="register-form" style={{ display: visibleDiv === "2" ? "block" : "none" }}>
                            <p className="label mt-5" style={{fontSize: 46,textAlign: "center"}}>Aktivasi Akun</p>
                            <p className="label p-3" style={{fontSize: 23, textAlign: "center"}}>Silahkan buka dan cek pesan terbaru untuk mengaktifkan akun BASOBO anda</p>
                            <div className="d-flex justify-content-center">
                                <img src={emailLogo} alt="email"/>
                            </div>
                            <p className="label" style={{margin:30,fontSize:12,textAlign:"center",color:"blueviolet",cursor:"pointer"}} onClick={ResendActivation}>Resend Email</p>

                            <div style={{width:"97%", position: 'absolute', bottom: -5, display: visibleDiv === "2" ? "block" : "none"}}> 
                                <p className="label" style={{fontSize:10, fontStyle:"italic", textAlign:"right"}}>Page will close after {countdown} seconds</p>
                            </div>                            
                        </Col>

                        <Col className="box-shadow" md={{size:10, offset:1}} style={{position:"relative", display: visibleDiv === "3" ? "block" : "none"}}>
                            <Button className="circle-grey-close" onClick={()=>showDiv("1")}>X</Button>
                        </Col>

                        <Col className="mt-auto">
                            <Alert color="success" isOpen={ShowAlert} toggle={onDismiss}>
                                Your email already sent.
                            </Alert>                    
                            <Alert color="danger" isOpen={ShowAlertError} toggle={onDismissError}>
                                {Error}
                            </Alert>
                        </Col>
                    </Row>
                </Card>
            </Container>
        </>
    );
}

export default RegisterPage;