import React, { useState,useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Card } from "reactstrap";
import { apiLoadTransactionSuspend } from "../apiaxios";
import { maskFormatCurrency } from "global";
import { TransactionCategoryList,TrackingList } from "itemlists";
import "../assets/css/container.css";
import emailIcon from '../assets/img/email-icon.svg';
import { RemarkList } from "itemlists";

const TransactionListPage = () => {
    const [Transaction, setTransaction] = useState([{transactionname:'Nama Transaksi', transactionamount:'0'
                                                    , transactioncategory: 'Beli/Jual', transactiondesc:'Keterangan Transaksi', tracking:0}]);

    useEffect(()=>{
        const FirstLoad = async () => {
            const response = await apiLoadTransactionSuspend();
            const { data } = response;
            if (data.success)
                setTransaction(data.data);
        };

        FirstLoad();
    }, []);

    const ShowTransaction = (e) => {
        history.push(`/root/transaction?id=${e._id}`);
    }

    const history = useHistory();
    return (
        <>
            <Container className="min-vh-100">
                <Card className="justify-content-center align-items-center border-0 bg-transparent">
                    <Row className="mt-5">
                        <p className="mt-3" style={{"fontWeight":"bold"}}>Daftar Transaksi</p>
                    </Row>

                    {Transaction.map((e,i) => 
                        <Row key={i} className="p-1 mt-2 box-shadow w-75" onClick={()=> e.remark !== 1 && ShowTransaction(e)}
                            style={{ cursor: e.remark == 1 ? 'not-allowed' : 'pointer', backgroundColor: e.remark == 1 ? "darkgray" : "" }}>              
                            <Col md={1} className="d-flex justify-content-center align-items-center">
                                <img src={emailIcon} width={50} height={50} alt="icon" />
                            </Col>
                            <Col md={11} style={{fontSize:13}}>
                                <Row className="d-flex align-items-center">
                                    <Col>
                                        <span style={{fontWeight:"bold"}}>{e.transactionname}</span>
                                    </Col>
                                    <Col>
                                        <center>{maskFormatCurrency(e.transactionamount)}</center>
                                    </Col>                            
                                    <Col className="d-flex justify-content-end">
                                        <span style={{fontWeight:"bold",marginRight:15}}>{TrackingList[e.tracking]}</span>
                                        <span>{TransactionCategoryList[e.transactioncategory]}</span>
                                    </Col>                         
                                </Row>       
                                <Row>
                                    <Col>
                                        <span>{e.transactiondesc}</span>
                                    </Col>
                                    <Col className="d-flex justify-content-end">
                                        <span style={{fontWeight:"bold",marginRight:37}}>{RemarkList[e.remark]}</span>
                                    </Col>
                                </Row>     
                            </Col>
                        </Row>                    
                    )}
                </Card>            
            </Container>
        </>
    );
}

export default TransactionListPage;