import React, {useState} from "react";
import { useLocation, Route, Switch } from "react-router-dom";

import Footer from "components/Footer";
import Sidebar from "components/Sidebar";

import routes from "../routes.js";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

function RootLayout() {
  const [Id, setId] = useState('');
  const location = useLocation();
  const mainPanel = React.useRef(null);
  
  const getRoutes = (routes) => {
    return routes.flatMap((prop, key) => {
      if (prop.layout === '/root') {
        if (prop.children) {
          return [
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />,
            ...getRoutes(prop.children)
          ];
        } else {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
      } else {
        return null;
      }
    });
  };
  
  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    setId(params.get('id'));

    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);
  return (
    <>
      <div className="wrapper">
        <Sidebar routes={routes.filter((e) => e.layout === '/root')} />
        <div className="main-panel" ref={mainPanel}>
          <div className="background-userlayout">
            <Switch>
              {getRoutes(routes)}
              <Redirect key={'default'} from='*' to={'/root/transactionlist'} />
            </Switch>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default RootLayout;