import React, {useState, useEffect, useRef} from "react";
import { Button, Col, FormGroup, Input, Label, Alert, Row } from "reactstrap";
import { apiAdminLogin,apiSendOTP,apiValidateOTP } from "apiaxios";
import "../assets/css/container.css";
import "../assets/css/form.css";
import "../assets/css/image.css";
import "../assets/css/button.css";
import "../assets/css/label.css";
import { useHistory } from "react-router-dom";
import { formatCountdown } from "global";
import { encryptData } from "global";

const LoginPage = () => {
    const [Email, setEmail] = useState('');
    const [Password, setPassword] = useState('');
    const [Error, setError] = useState('');
    const [Otp, setOtp] = useState('');
    
    const [ShowResend, setShowResend] = useState();
    const [visibleDiv, setVisibleDiv] = useState("1");
    const [countdown, setCountdown] = useState(0);
    const [ShowAlert, setShowAlert] = useState(false);
    const [ShowAlertError, setShowAlertError] = useState(false);

    const onDismiss = () => setShowAlert(false);
    const onDismissError = () => setShowAlertError(false);

    const Login = async() => {
        let response = await apiAdminLogin(Email, Password);
        let {data} = response;
        if (data.success)
        {
            sessionStorage.setItem('token', data.token);
            setShowResend(false);
            setCountdown(120);
            showDiv("2");
        }
        else if (!data.success) {
            setError(data.error);
            setShowAlertError(true);            
            setPassword('');
            sessionStorage.clear();
        }
        else {
            setError(data.error);
            setShowAlertError(true);            
            setEmail('');
            setPassword('');
        }
    }

    const ValidateOTP = async() => {
        const response = await apiValidateOTP(Otp);
        const {data} = response;
        if (data.success){
            sessionStorage.setItem('token', data.token);
            sessionStorage.setItem('id', encryptData({id: data.id, email: data.email}));
            window.location.reload();
        }
        else
        {
            setError(data.error);
            setShowAlertError(true);
        }
    }    

    const ResendOTP = async() => {
        setOtp('');

        const response = await apiSendOTP();
        const {data} = response;
        if (data.success){
            setShowAlert(true);
            setShowResend(false);
            setCountdown(120);
        }
        else{
            setError(data.error);
            setShowAlertError(true);
        }
    }

    const showDiv = (id) => {
        setVisibleDiv(id);
    };

    useEffect(() => {
        let timeout;
        if (!ShowResend){
            timeout = setTimeout(() => {
                setShowResend(true);
            }, countdown * 1000);
        
            const interval = setInterval(() => {
                setCountdown(prevCountdown => prevCountdown - 1);
            }, 1000);

            return () => {
                clearTimeout(timeout);
                clearInterval(interval);
            };
        }

    }, [ShowResend]);

    useEffect(() => {
        let timeout;
        timeout = setTimeout(() => {
            onDismiss();
            onDismissError();
        }, 5000);

        return () => clearTimeout(timeout);

    }, [ShowAlert, ShowAlertError]);

    const inputRefs = useRef([]);
    const OTPhandleChange = (e, index) => {
        const value = e.target.value.charAt(0);
        let newOtp = Otp.split('');
        newOtp[index] = value;
        setOtp(newOtp.join(''));

        if (value !== '' && index < 3) {
            const nextIndex = index + 1;
            if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex].focus();
            }
        }    
    };

    const history = useHistory();
    return (
        <>
            <Row className="circle-blue d-flex align-items-end justify-content-end">
                <div className="group">
                    <div className="ellipse"></div>
                    <div className="ellipse"></div>
                    <div className="ellipse-2"></div>
                    <div className="ellipse-3"></div>
                </div>

                <Col className="left-image"></Col>
                <Col className="register-form" style={{ display: visibleDiv === "1" ? "block" : "none" }}>
                    <p className="label" style={{fontSize: 46, textAlign:"center"}}>Welcome Admin</p>
                    <p className="label" style={{fontSize: 23, textAlign:"center"}}>Masukkan data anda untuk Login ke BASOBO</p>
                    <Row className="p-5">
                        <Col md={12} className="p-1">
                            <FormGroup>
                                <Label className="label">Email</Label>
                                <Input style={{border: "none"}} maxLength={50} placeholder="example@domain.com" value={Email} onChange={e => setEmail(e.target.value)}/>
                            </FormGroup>
                        </Col>

                        <Col md={12} className="p-1">
                            <FormGroup>
                                <Label className="label">Password</Label>
                                <Input style={{border: "none"}} type="password" maxLength={50} placeholder="Password" value={Password} onChange={e => {setPassword(e.target.value); setShowAlertError(false);}}/>
                            </FormGroup>
                        </Col>

                        <Col md={12} className="p-1 d-flex justify-content-center">
                            <Button className="rounded-blue-button w-25 mt-3" onClick={Login}>
                                Login
                            </Button>
                        </Col>               
                    </Row>
                </Col>

                <Col className="register-form" style={{ display: visibleDiv === "2" ? "block" : "none" }}>
                    <p className="label mt-5" style={{fontSize: 46, textAlign:"center"}}>Email OTP</p>
                    <p className="label p-3" style={{fontSize: 23, textAlign:"center"}}>Kode OTP akan dikirimkan melalui email</p>
                    <p className="label p-3" style={{fontSize: 23, textAlign:"center"}}>Masukkan Kode OTP</p>
                    <div style={{textAlign:"center", margin:20}}>
                        {Array.from({ length: 4 }).map((_, index) => (
                            <React.Fragment key={index}>
                                <input ref={(input) => (inputRefs.current[index] = input)} maxLength={1}
                                    style={{ width: '30px', margin: '5px', textAlign: 'center', fontSize: '16px' }}
                                    value={Otp[index] || ''} onChange={(e) => OTPhandleChange(e, index)}/>
                                {index < 3 && ' '}
                            </React.Fragment>
                        ))}
                    </div>

                    <div className="mt-3" style={{textAlign:"center"}}>
                        <Button className={`${!Otp?'disabled-button':''} rounded-blue-button`} onClick={ValidateOTP} disabled={!Otp}>
                            Selanjutnya
                        </Button>

                        <p className="label mt-2" style={{fontSize:12}}>{formatCountdown(countdown)} </p>
                        {ShowResend && (
                            <span className="label resend-otp" onClick={ResendOTP}>Resend OTP</span>
                        )}
                    </div>
                    <Alert color="success" isOpen={ShowAlert} toggle={onDismiss}>
                        Your email already sent.
                    </Alert>
                </Col>

                <Col>
                    <Alert color="danger" isOpen={ShowAlertError} toggle={onDismissError}>
                        {Error}
                    </Alert>
                </Col>
            </Row>
        </>
    );
}

export default LoginPage;