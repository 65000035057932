export const MonthNames = ['January','February','March','April','May','June',
                            'July','August','September','October','November','December'];

export const StatusActive = ['Inactive','Active'];
export const TransactionCategoryList = ['Buy','Sell'];
export const TrackingList = ['Not Pair', 'Pairing', 'Paired', 'Payment', 'Paid','Delivery','Delivered','Receive'];
export const RemarkList = ['Open', 'Cancel', 'Complete', 'Suspend'];
export const GenderList = ['Wanita', 'Pria'];

export const StatusValue = {
    inactive: 0,
    active: 1, 
};

export const CategoryValue = {
    buy: 0,
    sell: 1, 
};

export const TrackingValue = {
    not_pair: 0,
    pairing: 1, 
    paired: 2,
    payment: 3, 
    paid: 4,
    delivery: 5,
    delivered: 6,
    receive: 7
};

export const RemarkValue = {
    open: 0, 
    cancel: 1, 
    complete: 2, 
    suspend: 3
}