import TransaksiIcon from './assets/img/menu-icon/transaksi.svg';
import UserIcon from './assets/img/menu-icon/profile.svg';
import LogoutIcon from './assets/img/menu-icon/logout.svg';

import LoginPage from "./views/LoginPage";
import RegisterPage from "./views/RegisterPage";
import LogoutPage from "./views/LogoutPage";
import TransactionListPage from 'views/TransactionListPage';
import TransactionPage from 'views/TransactionPage';
import UserListPage from 'views/UserListPage';

const indexRoutes = [
  {
    path: "/login",
    name: "Login",
    icon: "fas fa-user-lock",
    component: LoginPage,
    layout: "/guest",
    sidebar: false
  },
  {
    path: "/register",
    name: "Register",
    icon: "fas fa-user-edit",
    component: RegisterPage,
    layout: "/guest",
    sidebar: false
  },
  {
    path: "/transactionlist",
    name: "List Transaksi",
    icon: TransaksiIcon,
    component: TransactionListPage,
    layout: "/root",
    sidebar: true
  },
  {
    path: "/userlist",
    name: "List User",
    icon: UserIcon,
    component: UserListPage,
    layout: "/root",
    sidebar: true
  },
  {
    path: "/transaction",
    name: "Transaksi",
    icon: TransaksiIcon,
    component: TransactionPage,
    layout: "/root",
    sidebar: false
  },  
  {
    path: "/logout",
    name: "Keluar",
    icon: LogoutIcon,
    component: LogoutPage,
    layout: "/root",
    sidebar: true
  }
];

export default indexRoutes;