import CryptoJS from "crypto-js";

export function maskFormatCurrency(e){
    return parseFloat(e).toLocaleString('id-ID', 
            { style: 'currency', currency: 'IDR', minimumFractionDigits: 0}
        )
};

export function ConvertToCurrency (value) {
    let formattedValue = "Rp. 0";
    if (value) {
        const numericValue = value.toString().replace(/[^0-9]/g, '');
        formattedValue = `Rp. ${new Intl.NumberFormat('id-ID').format(numericValue)}`;
    }

    return formattedValue;
};

export function maskFormatDate(e){
    const dateObj = new Date(e);

    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
};

export function maskFormatDateShort(e){
    const dateObj = new Date(e);

    const year = dateObj.getFullYear();
    const month = dateObj.toLocaleString('en-us', { month: 'short' });
    const day = String(dateObj.getDate()).padStart(2, '0');

    return `${day}-${month}-${year}`;
};

export function maskFormatNumber(e){
    const options = {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    };
      
    return new Intl.NumberFormat('en-US', options).format(e).replace(/\,/g, '.');
};

export function maskFormatPhone(value){
    if (value.charAt(0) === '0') {
        value = '+62' + value.slice(1);
    }

    return value;
};

export function encryptData(e) {
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(e), process.env.REACT_APP_ENCRYPT_KEY).toString();
    return encrypted;
};

export function decryptData(e){
    const decByte = CryptoJS.AES.decrypt(e, process.env.REACT_APP_ENCRYPT_KEY);
    const decData = JSON.parse(decByte.toString(CryptoJS.enc.Utf8));
    return decData;
};

export function formatCountdown (countdown) {
    const minutes = Math.floor(countdown / 60);
    const seconds = countdown % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};